exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bag-tsx": () => import("./../../../src/pages/bag.tsx" /* webpackChunkName: "component---src-pages-bag-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-move-tsx": () => import("./../../../src/pages/move.tsx" /* webpackChunkName: "component---src-pages-move-tsx" */),
  "component---src-pages-prop-tsx": () => import("./../../../src/pages/prop.tsx" /* webpackChunkName: "component---src-pages-prop-tsx" */),
  "component---src-pages-trip-tsx": () => import("./../../../src/pages/trip.tsx" /* webpackChunkName: "component---src-pages-trip-tsx" */)
}

